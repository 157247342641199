import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { ProjectDateRangeRequest } from '~common/components/filters/date-range/date-range.model'
import { ListRequest } from '~common/types/api'
import { CategoryMatrix, ComponentLevelMetrics, JsonResponse, PaginatedData } from '~core/models'
import { ApiService, UserService } from '~core/services'
import { Comment, CommentTopOccurrences, CommentLegacyListRequest } from '~features/comments/types'

import {
  Insight,
  InsightsOverview,
  InsightsRequest,
  Post,
  PostRequest,
  Reel,
  ReelRequest,
  Story,
  StoryRequest,
} from '../models'

@Injectable({
  providedIn: 'root',
})
export class InstagramAccountApiService {
  #defaultVersion = 'v1.1'
  private _prefix = '/{version}/projects/{projectId}/instagram-account'

  constructor(
    private apiService: ApiService,
    private userService: UserService,
  ) {}

  audienceChangeInsights({
    projectId,
    ...range
  }: ProjectDateRangeRequest): Observable<JsonResponse<ComponentLevelMetrics>> {
    const params = new HttpParams({ fromObject: { ...range } })
    return this.apiService.get(`${this.prefix(projectId)}/audience-change`, params).pipe(
      map((response) => ({
        data: {
          chart: {
            ...response.data.audienceChange.chart,
            datasets: [...response.data.audienceChange.chart.dataSets],
          },
          summary: { ...response.data.audienceChange.details },
        },
      })),
    )
  }

  topCommentOccurrences({
    projectId,
    ...range
  }: ProjectDateRangeRequest): Observable<JsonResponse<CommentTopOccurrences>> {
    const params = new HttpParams({ fromObject: { ...range } })
    return this.apiService.get(`${this.prefix(projectId)}/top-comment-occurrences`, params)
  }

  insights({ projectId, fields, ...range }: InsightsRequest): Observable<JsonResponse<Insight[]>> {
    const params = new HttpParams({ fromObject: { ...range, fields: fields.join(',') } })
    return this.apiService.get(`${this.prefix(projectId)}/insights`, params)
  }

  insightsOverview({ projectId, ...range }: ProjectDateRangeRequest): Observable<InsightsOverview> {
    const params = new HttpParams({ fromObject: { ...range } })
    return this.apiService.get(`${this.prefix(projectId, 'v1.2')}/overview`, params)
  }

  onlineFollowersPerHour({ projectId, ...range }: ProjectDateRangeRequest): Observable<JsonResponse<CategoryMatrix>> {
    const params = new HttpParams({ fromObject: { ...range } })
    return this.apiService.get(`${this.prefix(projectId)}/online-followers-per-hour`, params)
  }

  post({ projectId, postId }: PostRequest): Observable<JsonResponse<Post>> {
    return this.apiService.get(`${this.prefix(projectId)}/media/${postId}`)
  }

  postComments({ projectId, commentableId, ...query }: CommentLegacyListRequest): Observable<PaginatedData<Comment>> {
    const params = new HttpParams({ fromObject: { ...query } })
    return this.apiService.get(`${this.prefix(projectId)}/media/${commentableId}/comments`, params)
  }

  posts(projectId: string, query: ListRequest): Observable<PaginatedData<Post>> {
    const params = new HttpParams({ fromObject: { ...query } })
    return this.apiService.get(`${this.prefix(projectId, 'v1.2')}/media`, params)
  }

  reel({ projectId, reelId }: ReelRequest): Observable<JsonResponse<Reel>> {
    return this.apiService.get(`${this.prefix(projectId)}/reels/${reelId}`)
  }

  reels(projectId: string, query: ListRequest): Observable<PaginatedData<Reel>> {
    const params = new HttpParams({ fromObject: { ...query } })
    return this.apiService.get(`${this.prefix(projectId, 'v1.2')}/reels`, params)
  }

  stories(projectId: string, query: ListRequest): Observable<PaginatedData<Story>> {
    const params = new HttpParams({ fromObject: { ...query } })
    return this.apiService.get(`${this.prefix(projectId, 'v1.2')}/stories`, params)
  }

  storiesActivityInsights({
    projectId,
    ...range
  }: ProjectDateRangeRequest): Observable<JsonResponse<ComponentLevelMetrics>> {
    const params = new HttpParams({ fromObject: { ...range } })
    return this.apiService.get(`${this.prefix(projectId)}/stories-activity`, params).pipe(
      map((response) => ({
        data: {
          ...response.data.overview,
          chart: {
            ...response.data.overview.chart,
            datasets: [...response.data.overview.chart.dataSets],
          },
        },
      })),
    )
  }

  story({ projectId, storyId }: StoryRequest): Observable<JsonResponse<Story>> {
    return this.apiService.get(`${this.prefix(projectId)}/stories/${storyId}`)
  }

  private prefix(projectId: string, version: string = this.#defaultVersion): string {
    return this._prefix.replace('{projectId}', projectId).replace('{version}', version)
  }
}
